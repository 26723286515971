// frame sdk types
// @AUTHOR CAIHUAZHI <huazhi.chz@alibaba-inc.com>
// @CREATE 2023/11/27 16:28

import { type RequestOptions } from '@alife/saber-simplify';

export enum ActionType {
  FETCH = 'FETCH',
  CUSTOM = 'CUSTOM',
}

export interface IFrameSDKProps {
  /**
   * SPI 请求的 host
   * @default 'https://api.aidc-ai.com/rest'
   */
  host?: string;
}

export interface IExtendCtx {
  /** @deprecated 用于区分一些新老逻辑，自动注入，请勿手动传此参数 */
  isSyncAPI?: boolean;
}

export interface IFetchOptions extends RequestOptions<IExtendCtx> {
  /** 业务参数 */
  data?: Record<string, any>;
  /** 用于在 POST/PUT 请求时在 URL 带上参数 */
  search?: Record<string, any>;
  /** 请求 host，用于覆盖初始化的 host */
  host?: string;
  /** 超时时间(单位 ms)，默认 60000ms，即 60s */
  timeout?: number;
}

/** 传输的消息格式 */
export interface IMessageVO<T = Record<string, any>> {
  sequenceId: string;
  type: ActionType;
  /** 传输的数据 */
  payload: T;
  /** 错误信息（如有） */
  error?: any;
}

export interface IFetchMessagePayload {
  api: string;
  options: IFetchOptions;
}

/** 约定的 isv 的接口返回格式 */
export interface IResponseVO<D = any> {
  success: boolean;
  message?: string;
  data: D;

  [x: string]: any;
}

/** Open API 的接口返回格式 */
export interface ISPIResponse {
  /** 请求ID，可用于协助服务端排查问题 */
  request_id: string;
  /** 错误码，成功时为 '0' */
  code: string | number;
  /** @deprecated 接口是否成功，一般用 code 代替 */
  success?: boolean;

  [x: string]: any;
}
