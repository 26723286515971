// common utils
// @AUTHOR CAIHUAZHI <huazhi.chz@alibaba-inc.com>
// @CREATE 2023/11/28 10:49

// 这里不 import Saber 会导致本地调试时编译有问题，目前还没有找到原因，只能先这样处理。
import Saber, { isNullValue } from '@alife/saber-simplify';
import { IMessageVO } from './types';

/** 创建唯一的 uuid */
export function uuid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0;
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16).toUpperCase();
  });
}

/** 将参数经过空值过滤、stringify 处理，方便服务端对此作加签 */
export function getStringifyParams(params: Record<string, any>): Record<string, string> {
  const paramsKeys = Object.keys(params);
  paramsKeys.sort();

  return paramsKeys.reduce((prev, key) => {
    const value: any = params[key];
    // 如果是 空，或 blob(含 file)，则不加签
    if (isNullValue(value) || value === '' || value instanceof Blob) {
      return prev;
    }
    if (value && typeof value === 'object') {
      return { ...prev, [key]: JSON.stringify(value) };
    }

    return { ...prev, [key]: `${value}` };
  }, {} as Record<string, string>);
}

/** 消息序列化处理 */
export function encodeMessage<T = any>(scope = '', data: IMessageVO<T>): string {
  let str = JSON.stringify(data);
  str = window.btoa(encodeURIComponent(str));
  return `[OPEN_FRAME_SDK::${scope}]${str}`;
}

/** 消息反序列化处理 */
export function decodeMessage<T = any>(scope = '', message: string): IMessageVO<T> | null {
  if (!message || typeof message !== 'string') return null;
  const prefix = `[OPEN_FRAME_SDK::${scope}]`;
  if (message.indexOf(prefix) !== 0) return null;

  message = message.replace(prefix, '');
  try {
    message = decodeURIComponent(window.atob(message));
    const obj = JSON.parse(message);
    // 未知消息
    if (!obj.sequenceId) return null;

    return obj;
  } catch (ex) {
    console.warn('[ERROR] in decodeMessage', ex);
    return null;
  }
}

export function truncateString(str?: string, max = 100) {
  if (!str || typeof str !== 'string') return '';
  const len = str.length;
  if (len <= max) return str;

  return `${str.substring(0, Math.ceil(max / 2))}...${str.substring(len - Math.floor(max / 2))}`;
}
