// iframe 外层引用的 sdk，用于透传请求
// @AUTHOR CAIHUAZHI <huazhi.chz@alibaba-inc.com>
// @CREATE 2023/11/27 14:39

import { logger } from '@alife/saber-simplify';
import { IFRAME_CLASS_NAME } from '../common/config';
import { encodeMessage, decodeMessage, truncateString } from '../common/utils';
import { request } from '../common/service';
import { ActionType, IFetchMessagePayload, IMessageVO } from '../common/types';

class MainSDK {
  static displayName = 'MainSDK';

  apiMap: Record<string, string> = {};
  dataType: 'json' | 'formData';
  requestOptions: Record<string, any> = {};

  constructor() {
    this.bindMessageListener();

    const initConfig = (window as any).INTL_OP_FRAME_SDK_CONFIG || {};
    this.apiMap = initConfig.apiMap || this.apiMap;
    this.dataType = initConfig.dataType || 'json';
    this.requestOptions = initConfig.requestOptions || {};
  }

  private async handleFetchMessage(sequenceId: string, payload: IFetchMessagePayload) {
    logger.debug('[MainSDK] handleFetchMessage', sequenceId, payload);

    const { api, options = {}} = payload || {};
    const fetchApi = this.apiMap[api] || api;

    try {
      const response = await request(fetchApi, {
        ...options,
        dataType: this.dataType,
        ...this.requestOptions,
      });
      this.sendMessage({
        sequenceId,
        type: ActionType.FETCH,
        payload: response,
      });
    } catch (ex) {
      this.sendMessage({
        sequenceId,
        type: ActionType.FETCH,
        payload: {},
        error: ex,
      });
    }
  }

  private sendMessage(message: IMessageVO) {
    const frames = document.querySelectorAll<HTMLIFrameElement>(`.${IFRAME_CLASS_NAME}`);
    if (!frames.length) {
      return logger.error('[MainSDK] frame not found, query selector: ', `.${IFRAME_CLASS_NAME}`);
    }
    const msg = encodeMessage('MainSDK', message);
    frames.forEach((f) => {
      const { origin } = new URL(f.getAttribute('src') || '');
      f.contentWindow?.postMessage(msg, origin);
    });
  }

  private bindMessageListener() {
    window.addEventListener('message', (e) => {
      logger.debug(`[MainSDK] receive message from ${e.origin}`, truncateString(e.data));
      // 只接收来自内层的消息，防止处理自己的消息
      const message = decodeMessage<IFetchMessagePayload>('FrameSDK', e.data);
      if (!message) return;
      const { sequenceId, type, payload } = message;
      if (type === ActionType.FETCH) {
        this.handleFetchMessage(sequenceId, payload);
      }
    });
  }
}

document.addEventListener('DOMContentLoaded', () => {
  (window as any).__openFrameSDK = new MainSDK();
});
