// custom response error
// @AUTHOR CAIHUAZHI <huazhi.chz@alibaba-inc.com>
// @CREATE 2023/02/15 20:33

export class CustomError<P = any> {
  message: string;
  code?: string | number;
  payload?: P;

  constructor(message: string, code?: string | number, payload?: P) {
    this.message = message;
    this.code = code;
    this.payload = payload;
  }

  toString() {
    return this.message;
  }
}
