// request service
// @AUTHOR CAIHUAZHI <huazhi.chz@alibaba-inc.com>
// @CREATE 2023/11/28 16:13

import Saber, { logger, RequestOptions } from '@alife/saber-simplify';
import { CustomError } from './custom-error';
import { IExtendCtx } from './types';

const saber = Saber.singleton<IExtendCtx>();

const getErrorMessage = (response: Record<string, any> = {}) => {
  return (
    response.message ||
    response.msg ||
    response.err_msg ||
    response.error_msg ||
    response.errorMessage ||
    response.error?.message
  );
};

// 统一错误处理
saber.use(async (ctx) => {
  try {
    await ctx.next();

    const response = ctx.response || {};
    if (response.success === false || ('code' in response && ![0, 200].includes(+response.code))) {
      throw new CustomError(getErrorMessage(response), response.code || -1, response);
    }
  } catch (error: any) {
    logger.error(`ERROR for ${ctx.api}`, error.message);
    throw error;
  }
});

const AEResErrorWrapper = 'error_response';
// sync 接口的特殊处理
saber.use(async (ctx) => {
  await ctx.next();

  let response = ctx.response || {};
  if (Object.keys(response).length !== 1) return;

  // sync 模式某些情况下，会将错误放到 error_response 中
  if (AEResErrorWrapper in response && response[AEResErrorWrapper]) {
    response = response[AEResErrorWrapper];
  }

  ctx.response = response;
});

// 参数修正
saber.use(async (ctx) => {
  ctx.serializeOptions = {
    listHandler: 'STRINGIFY',
    holdEmpty: true,
  };

  await ctx.next();
});

export function request<D = any>(url: string, params?: RequestOptions<IExtendCtx>) {
  return saber.request<D>(url, params);
}
